import { Link } from "react-router-dom";
import { NavLink } from "react-router-dom";
import { motion } from "framer-motion";
import { RiMenu3Fill } from "react-icons/ri";

import Logo from "../../assets/innovator-club-removebg-preview.png";
import Drawer from "../Drawer";
import useDrawer from "../../utils/useDrawer";
import { NAV_ITEMS_ARRAY } from "../../utils/constants";

import FloatingNav from "./FloatingNav";

import "./Header.css";

const Navbar = () => {
  const { setState: setSideBar } = useDrawer();

  return (
    <>
      {/* sidebar */}
      <>
        <Drawer />
        <FloatingNav />
      </>
      <div className=" w-full text-white px-10">
        <nav className="main-nav flex justify-around items-center grid-cols-3">
          {/* 1st logo part  */}
          <div className="logo flex flex-col w-full justify-center col-span-1 items-center">
            <Link to="/">
              <img src={Logo} className="h-fit w-fit ml-2" alt="nothing" />
            </Link>
          </div>

          {/* 2nd menu part  */}
          <div className="menu-link flex justify-end  w-full col-span-2 items-center">
            <ul className=" flex justify-evenly list lg:ml-6  ">
              {NAV_ITEMS_ARRAY.map((item, index) => (
                <li
                  className="lg:ml-8 "
                  key={item.title + index + "DESKTOP_NAV_ITEMS"}
                >
                  <NavLink
                    to={item.path}
                    className="text-white opacity-60 hover:opacity-100 transition-all duration-300 underline-offset-2"
                  >
                    {item.title}
                  </NavLink>
                </li>
              ))}
            </ul>
          </div>

          {/* 3rd social media links */}
          <div className="social-media">
            {/* hamburget menu start  */}
            <div className="hamburger-menu">
              <motion.button
                className="p-0 m-0 flex items-center justify-center"
                onClick={() => setSideBar(true)}
                whileTap={{ scale: 0.8 }}
              >
                <RiMenu3Fill opacity={0.6} />
              </motion.button>
            </div>
          </div>
        </nav>
      </div>
    </>
  );
};

export default Navbar;
