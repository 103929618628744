import React from "react";

import Card from "../../Components/Team_Page_Card/Card";
import teamImg from "../../assets/IMG_0700.png";
import Json from "../../Team.json";
import Header from "../../Components/Header/Header";
import Footer from "../../Components/Footer/Footer";

import "./TeamPage.css";

const Team = () => {
  React.useEffect(() => {
    window.scrollTo(0, 0);
  }, []);
  return (
    <>
      <Header />
      <div className="text-white bg-[#0d102c]">
        <div className="team">
          <h2 className="team-title text-white mt-4">Our Team</h2>
          <img className="team-img" src={teamImg} alt="Group of students" />
          <p className="team-description">
            INNOVATOR CLUB of L.D. College of Engineering, Ahmedabad is
            pioneered with a vision to inculcate practical skills and a feeling
            of innovation among the students. Since its establishment the club
            has been very active and has carried out various activities to
            create enthusiasm among students to explore different avenues in the
            realm of technology. For the first time, we are introducing a
            National Level tech-fest "IRADA-2022". It's an initiative by our
            president Mr. Nayan Savaliya.
          </p>
        </div>

        <div>
          <center>
            <h2 className="team-title text-white mt-4">Adminstrative team</h2>
          </center>
          <div className="cards">
            {Json["Adminstrative_team"]["Members"].map((user) => {
              return (
                <Card
                  name={user.name}
                  designation={user.role}
                  image={user.image}
                  dept={user.dept}
                  instagram={user.instagram}
                  linkdin={user.linkdin}
                />
              );
            })}
          </div>
        </div>
        <br />

        <center>
          <h2 className="team-title text-white mt-4">Team</h2>
        </center>
        <div className="cards">
          {Json["INNO_TEAM"]["Members"].map((user) => {
            return (
              <Card
                name={user.name}
                designation={user.role}
                image={user.image}
                dept={user.dept}
                instagram={user.instagram}
                linkdin={user.linkdin}
              />
            );
          })}
        </div>
      </div>
      <Footer />
    </>
  );
};

export default Team;
