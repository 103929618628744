import React from "react";

import menwomen from "../../assets/menwomen.png";

import "./Home2.css";
export const page2 = () => {
  return (
    <div id="secondpage" className="flex flex-col justify-around">
      <div id="p2head" className="p-4 space-y-8">
        <p>Who We Are &#63;</p>
        <p className="text-xl xl:mt-8  text-left ml-8  w-auto">
          {" "}
          Our goal is to enhance the technical skill, improve team working and
          communication skills of students
        </p>
      </div>

      <div id="con2" className="grid place-items-center xl:mb-14">
        <div id="block" className="grid xl:grid-cols-3 grid-cols-1">
          <div id="firstpart" className="col-span-2">
            <div id="p1" className="text-justify">
              It provides a great platform to the students to grow their
              network.The students are guided by the club to find their spark in
              the field of innovation. It has organized a national level tech
              fest, financial literacy event, industrial visits,Hackathons, quiz
              competitions, talk shows and many more events.
            </div>

            <div id="p3">
              We provide you a platform for development of coordination and
              communication amongst the students to share their ideas and work
              towards a common goal
            </div>
          </div>

          <div className="col-span-1 ">
            <img src={menwomen} alt="" id="menwomen" />
          </div>
        </div>
      </div>
    </div>
  );
};

export default page2;
