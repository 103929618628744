/**
 * This hook is the global context state used to toggle the drawer state from   any component in the component tree or pages
 */

import { create } from "zustand";

const useDrawer = create((set) => ({
  state: false,
  setState(newState) {
    set({ state: newState });
  },
}));

export default useDrawer;
