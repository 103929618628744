import React, { useEffect } from "react";

import Header from "../../Components/Header/Header";
import img from "../../assets/Robotics.png";
import Footer from "../../Components/Footer/Footer";

import "./Blog.css";
import "../../Components/Footer/Footer.css";

function Blog() {
  useEffect(() => {
    window.scrollTo(0, 0);
  }, []);
  return (
    <>
      <Header />
      <div className=" py-6">
        <img className=" xl:w-1/4 mx-auto" src={img} alt="" />
        <h1 className=" text-center text-white text-3xl mb-8">
          Comming Soon...
        </h1>
      </div>

      <Footer />
    </>
  );
}

export default Blog;
