import React from 'react'
import group from '../../assets/Group.png'
import './Home4.css'

const Home4 = () => {
  return (
    <div className=" home4 p-4 gap-2 flex flex-col justify-around bg-white">
      <div className=" space-y-3 my-2 mx-2 text-center">
        <h1 className=" font-bold text-4xl text-black">Achivement</h1>
        <p className=" text-xl text-black">
          Innovation is progress in the face of tradition.
        </p>
      </div>

      <div className="rounded-[50px] p-6 flex-1 w-11/12 home4_container mx-auto flex flex-col justify-center">
        <div>
          <img className=" mx-auto" src={group} alt="" />
        </div>

        <div className="grid  xl:grid-cols-2 grid-cols-1">
          <div className="col-span-1 xl:border-r-2 border-black mb-4">
            <ul className="dots mb-4 text-black ">
              <li>
                &nbsp; Smart Gujarat for New India hackathon was organised by
                the state government.
              </li>
              <li>
                &nbsp; In this hackathon, total 10 teams from the club
                represented the college and gave an excellent performance. 6 out
                of these 10 teams reached the final round held at PDPU
                Gandhinagar. One team emerged out to be the second winner of the
                competition.
              </li>
              <li>
                &nbsp; Two out of four teams from the Innovator club won at the
                Girlscript Hackathon
              </li>
            </ul>
          </div>
          <div className="col-span-1 ">
            <ul className="dots text-black">
              <li>
                &nbsp; One team from the club won at the Dronathon and one at
                the Enginium Hackathon. Another team also won the Startup
                Weekend.
              </li>
              <li>
                &nbsp; Chaxu Valand, Vaibhav Mehta, Vibhav Nirmal, and Adarsh
                Shah, fourth semester Computer Engineering students, have
                created an online system for LD College of Engineering. This
                system was about grievance procedure, which is a machinery to
                sort out issues between student and college.
              </li>
            </ul>
          </div>
        </div>
      </div>
    </div>
  )
}

export default Home4
