import React from "react";
import { Link } from "react-router-dom";

import img1 from "../../assets/hero_image.svg";
import logo2 from "../../assets/img1.png";
import Typing from "../TypingEffect/Typing";
import "./Home1.css";

function Home1() {
  return (
    <>
      <section>
        <div className="grid xl:grid-cols-2 grid-cols-1  xl:py-14 ">
          <div className="flex justify-center align-items-center ">
            <img src={img1} alt="images" className=" mt-4 w-1/2 xl:w-[70%]" />
          </div>

          <div className="flex flex-col align-items-center justify-center ">
            <div className="flex justify-center">
              <img
                src={logo2}
                alt=""
                className="w-1/2 logo2 pointer-events-none"
              />
            </div>
            <div>
              <p className="content text-justify p-4">
                INNOVATOR CLUB of L.D. College of Engineering, Ahmedabad is
                pioneered with a vision to inculcate practical skills and a
                feeling of innovation among the students. Since its
                establishment the club has been very active and has carried out
                various activities to create enthusiasm among students to
                explore different avenues in the realm of technology.The club is
                founded for the all rounder personality development of students.
              </p>
            </div>
            <div className="btn flex justify-center align-center">
              <Link to="/events">
                <button
                  id="mainbtn"
                  className="hover:bg-orange-700 hover:color:white"
                >
                  Events
                </button>
              </Link>
              <Link to="/team">
                <button id="mainbtn">Our Team</button>
              </Link>
            </div>
          </div>
        </div>
        <div className="text-center p-4 mt-10">
          <Typing />
        </div>
      </section>
    </>
  );
}

export default Home1;
