import React from "react";
import { useEffect } from "react";
import { useParams } from "react-router-dom";

import Team_Members_Page from "./Team_Members_Page.module.css";
import Json from "../../Team.json";
import Header from "../../Components/Header/Header";
import Footer from "../../Components/Footer/Footer";
import CardMember from "../../Components/Team_Members_Card/Team_Card";

const Social_Team = () => {
  useEffect(() => {
    window.scrollTo(0, 0);
  }, []);
  const { id } = useParams();
  return (
    <>
      <Header />
      <div className={Team_Members_Page.container_Team_I}>
        <h1 className="font-bold text-center mt-4 header">
          {Json[id]["name"]}
        </h1>
        <p className="text-white mt-10 text-xl break-normal">
          Lorem, ipsum dolor sit amet consectetur adipisicing elit. Quam minima,
          cumque tempore voluptas est consequuntur odio laboriosam porro
          perferendis aut laudantium eos, qui illo provident, nesciunt
          doloremque numquam cupiditate incidunt.
        </p>
        {/* cards sec */}
        <div className={Team_Members_Page.card_section_Team_I}>
          {Json[id]["Members"].map((user) => (
            <CardMember props={user} key={user.id} />
          ))}
        </div>
      </div>
      <Footer />
    </>
  );
};

export default Social_Team;
