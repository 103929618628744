import { BrowserRouter, Routes, Route } from "react-router-dom";

import HomePage from "./pages/HomePage/HomePage";
import TeamPage from "./pages/TeamPage/TeamPage";
import EventPage from "./pages/Events/Event";
import Blog from "./pages/Blog/Blog";
import EventDetails from "./pages/EventDetails/EventDetails";
import TeamMembers from "./pages/Team_Members_Page/Team_Members_Page";
import ActivityPage from "./pages/Activities/Activity";

import "./App.css";

function App() {
  return (
    <div className="App">
      <BrowserRouter>
        <Routes>
          <Route path="/teamMembers/:id" element={<TeamMembers />} />
          <Route path="/events/:eventname" element={<EventDetails />} />
          <Route path="/blog" element={<Blog />} />
          <Route path="/events" element={<EventPage />} />
          <Route path="/activities" element={<ActivityPage />} />
          <Route path="/team" element={<TeamPage />} />
          <Route path="/" element={<HomePage />} />
        </Routes>
      </BrowserRouter>
    </div>
  );
}

export default App;
