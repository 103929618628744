import React, { useEffect, useState } from "react";
import { Link } from "react-router-dom";
import {
  VerticalTimeline,
  VerticalTimelineElement,
} from "react-vertical-timeline-component";
import "react-vertical-timeline-component/style.min.css";

import irada from "../../assets/irada.png";
import Header from "../../Components/Header/Header";
import Footer from "../../Components/Footer/Footer";

import "./Event.css";

function Event() {
  useEffect(() => {
    window.scrollTo(0, 0);
  }, []);
  const [first, setFirst] = useState(true);

  // functions

  const select_first = () => {
    setFirst(true);
  };

  const select_second = () => {
    setFirst(false);
  };

  return (
    <>
      <Header />
      <div className="bg-[#0D102C] p-4">
        <h1 className=" font-bold text-4xl text-white  text-center">Events</h1>
        <div className="floating my-4 flex xl:w-[40%] w-3/4  justify-between xl:gap-4  mx-auto  ">
          <button
            onClick={select_first}
            className={`border-b text-white ${
              !first && "border-black font-semibold"
            } px-2 py-1 text-xl`}
          >
            Past Events
          </button>
          <button
            onClick={select_second}
            className={` border-b text-white px-2 py-2 text-xl ${
              first && "border-black font-semibold"
            } `}
          >
            Upcoming Events
          </button>
        </div>
        {first ? (
          <div className="cont">
            <VerticalTimeline lineColor="white" layout="2-columns">
              <VerticalTimelineElement
                className="vertical-timeline-element--work flex flex-col "
                contentArrowStyle={{ borderRight: "7px solid white" }}
                iconStyle={{ background: "rgb(33, 150, 243)", color: "#fff" }}
              >
                <Link to="/events/INN-O-SPARK-22">
                  <img
                    src={
                      "https://firebasestorage.googleapis.com/v0/b/fir-45a45.appspot.com/o/Team%20Members%2Finnospark.webp?alt=media&token=3690c9db-91bb-4b94-863e-31ec985d1485"
                    }
                    className="w-fit ml-auto"
                    alt="irada"
                  />
                </Link>
                <h3 className=" text-black font-semibold text-center text-2xl mt-3">
                  INN-O-SPARK
                </h3>
                <p className=" text-center text-black ">NOV 2022</p>
              </VerticalTimelineElement>
              <VerticalTimelineElement
                className={"vertical-timeline-element--work rounded-xl"}
                contentArrowStyle={{ borderRight: "7px solid white" }}
                iconStyle={{
                  background: "rgb(33, 150, 243)",
                  color: "#000000",
                }}
              >
                <Link to="/events/ORIENTATION">
                  <img
                    src={
                      "https://firebasestorage.googleapis.com/v0/b/portfolio-d8525.appspot.com/o/Banner%2Forientation%202k22.png?alt=media&token=ba19df54-2bf2-4067-b1cd-62c11b0f18c0"
                    }
                    className=""
                    alt="irada"
                  />

                  <h3 className=" text-black font-semibold text-center text-2xl mt-3">
                    ORIENTATION 2022
                  </h3>
                </Link>
                <p className=" text-center text-black ">OCT 2022</p>
              </VerticalTimelineElement>
              <VerticalTimelineElement
                className="vertical-timeline-element--work  rounded-xl"
                contentArrowStyle={{ borderRight: "7px solid  white" }}
                iconStyle={{ background: "rgb(33, 150, 243)", color: "#fff" }}
              >
                <Link to="/events/PROFINNO">
                  <img
                    src={
                      "https://firebasestorage.googleapis.com/v0/b/portfolio-d8525.appspot.com/o/Banner%2FProfinno%20Banner.png?alt=media&token=e3f80372-0b6a-4fef-ad60-b863e710b6bd"
                    }
                    alt=""
                  />
                </Link>
                <h3 className=" text-black font-semibold text-center text-2xl mt-3">
                  PROFINNO
                </h3>
                <p className=" text-center text-black ">APRIL 2022</p>
              </VerticalTimelineElement>

              <VerticalTimelineElement
                className={"vertical-timeline-element--work  rounded-xl"}
                contentArrowStyle={{ borderRight: "7px solid white" }}
                iconStyle={{
                  background: "rgb(33, 150, 243)",
                  color: "#000000",
                }}
              >
                <Link to="/events/IRADA">
                  <img
                    src={
                      "https://firebasestorage.googleapis.com/v0/b/portfolio-d8525.appspot.com/o/Banner%2Firada.png?alt=media&token=1b7ce47d-df19-4311-b223-bf1f439c4e29"
                    }
                    className=""
                    alt="irada"
                  />
                </Link>
                <h3 className=" text-black font-semibold text-center text-2xl mt-3">
                  IRADA 2022
                </h3>
                <p className=" text-center text-black ">FEB 2022</p>
              </VerticalTimelineElement>

              <VerticalTimelineElement
                className="vertical-timeline-element--work  rounded-xl"
                contentArrowStyle={{ borderRight: "7px solid  white" }}
                iconStyle={{ background: "rgb(33, 150, 243)", color: "#fff" }}
              >
                <Link to="/events/INNOVATHON">
                  <img
                    src="https://firebasestorage.googleapis.com/v0/b/portfolio-d8525.appspot.com/o/Banner%2FINNOVATHON%20-%20Post%20(Instagram%20Post)%20(Card%20(Landscape)).png?alt=media&token=9dafe8d6-1ecf-4205-8911-ab53a055dc47"
                    className="w-fit ml-auto"
                    alt="irada"
                  />
                </Link>
                <h3 className=" text-black font-semibold text-center text-2xl mt-3">
                  INNOVATHON
                </h3>
                <p className=" text-center text-black ">FEB 2022</p>
              </VerticalTimelineElement>

              <VerticalTimelineElement
                className="vertical-timeline-element--work"
                contentArrowStyle={{ borderRight: "7px solid  white" }}
                iconStyle={{ background: "rgb(33, 150, 243)", color: "#fff" }}
              >
                <Link to="/events/INNOVICHAR">
                  <img
                    src={
                      "https://firebasestorage.googleapis.com/v0/b/portfolio-d8525.appspot.com/o/Banner%2Finnovichar.png?alt=media&token=85bd2792-88d6-49ae-9540-90d6d83c625d"
                    }
                    alt=""
                  />
                </Link>
                <h3 className=" text-black font-semibold text-center text-2xl mt-3">
                  INNOVICHAR
                </h3>
                <p className=" text-center text-black ">FEB 2022</p>
              </VerticalTimelineElement>
              <VerticalTimelineElement
                className="vertical-timeline-element--work  rounded-xl"
                contentArrowStyle={{ borderRight: "7px solid  white" }}
                iconStyle={{ background: "rgb(33, 150, 243)", color: "#fff" }}
              >
                <Link to="/events/INN-O-SPARK">
                  <img
                    src={
                      "https://firebasestorage.googleapis.com/v0/b/portfolio-d8525.appspot.com/o/Banner%2Finno-spark.jpg?alt=media&token=fcadc7ba-6b32-4e0c-a637-29742a10b4e1"
                    }
                    className="w-fit ml-auto"
                    alt="irada"
                  />
                </Link>
                <h3 className=" text-black font-semibold text-center text-2xl mt-3">
                  INN-O-SPARK
                </h3>
                <p className=" text-center text-black ">OCT 2021</p>
              </VerticalTimelineElement>
              <VerticalTimelineElement
                className="vertical-timeline-element--work"
                contentArrowStyle={{ borderRight: "7px solid  white" }}
                iconStyle={{ background: "rgb(33, 150, 243)", color: "#fff" }}
              >
                <Link to="/events/Entreprenour_Show">
                  <img
                    src={
                      "https://firebasestorage.googleapis.com/v0/b/portfolio-d8525.appspot.com/o/Banner%2Fentrepre.jpg?alt=media&token=ec21de59-def9-4fb2-bd72-cca6a307eb19"
                    }
                    alt=""
                  />
                </Link>
                <h3 className=" text-black font-semibold text-center text-2xl mt-3">
                  ENTREPRENEURS SHOW
                </h3>
                <p className=" text-center text-black ">JULY 2021</p>
              </VerticalTimelineElement>
              <VerticalTimelineElement
                className="vertical-timeline-element--work  rounded-xl"
                contentArrowStyle={{ borderRight: "7px solid  white" }}
                iconStyle={{ background: "rgb(33, 150, 243)", color: "#fff" }}
              >
                <Link to="/events/HOUR-OF-CODE">
                  <img
                    src={
                      "https://firebasestorage.googleapis.com/v0/b/portfolio-d8525.appspot.com/o/Banner%2F1_20221010_004228_0000.png?alt=media&token=0992a1f9-3d27-4cf4-950e-64df9fcfab6d"
                    }
                    className="w-fit ml-auto"
                    alt="irada"
                  />
                </Link>
                <h3 className=" text-black font-semibold text-center text-2xl mt-3">
                  HOUR-OF-CODE
                </h3>
                <p className=" text-center text-black ">SEP 2019</p>
              </VerticalTimelineElement>
            </VerticalTimeline>
          </div>
        ) : (
          <div>
            <VerticalTimeline lineColor="white" layout="2-columns">
              <VerticalTimelineElement
                className={"vertical-timeline-element--work  rounded-xl"}
                contentArrowStyle={{ borderRight: "7px solid white" }}
                iconStyle={{
                  background: "rgb(33, 150, 243)",
                  color: "#000000",
                }}
              >
                <Link to="">
                  <img
                    src={irada}
                    className=""
                    style={{ width: "100%" }}
                    alt="irada"
                  />
                </Link>
                <h3 className=" text-black font-semibold text-center text-2xl mt-3">
                  IRADA 2023
                </h3>
                <p className=" text-center text-black ">Coming Soon</p>
              </VerticalTimelineElement>
            </VerticalTimeline>
          </div>
        )}
      </div>
      <div className=" grid place-items-center  w-full  my-8 ">
        <div>
          <p className="text-2xl text-bold mr-4">Vistors Count</p>
        </div>
        <div>
          <img
            src="https://counter4.optistats.ovh/private/freecounterstat.php?c=27uu2tnjl9frenrpm4as7md1y8rfbruw"
            border="0"
            title="Innovator Club Vistor Counter"
            alt="Vister Counter"
          />
        </div>
      </div>
      <Footer />
    </>
  );
}

export default Event;
