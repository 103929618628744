import React from "react";
import { Link } from "react-router-dom";

import clubimage from "../../assets/innovator-club-removebg-preview.png";
import "./Footer.css";

const style = {
  backgroundColor: "#0D102C",
};

function Footer() {
  return (
    <footer
      className="text-center lg:text-left border-t-2 p-4  border-white relative bottom-0 w-full "
      style={style}
    >
      <div className=" text-center">
        <div className="grid grid-1 md:grid-cols-2 lg:grid-cols-3  ">
          <div className="flex  flex-col justify-center align-items-center text-white  lg:mt-2 mt-4 ">
            <Link to="/">
              <img
                src={clubimage}
                alt="imagedonw"
                className="h-auto w-40 mx-auto"
              />
            </Link>
            <p className="break-normal xl:text-left text-justify lg:pl-12  mt-4">
              INNOVATOR CLUB of L.D. College of Engineering, Ahmedabad is
              pioneered with a vision to inculcate practical skills and a
              feeling of innovation among the students.{" "}
            </p>
          </div>
          <div className="flex flex-col  lg:mt-2 mt-4  align-items-center">
            <h6 className="uppercase text-lg font-semibold  flex justify-center text-white ">
              SITEMAP
            </h6>
            <ul className="flex flex-col  links">
              <li className=" text-md text-white">
                <Link
                  to="/"
                  className="text-white hover:border-b-2 "
                  style={{ textDecoration: "none", fontFamily: "Lato" }}
                >
                  HOME
                </Link>
              </li>
              <li className=" text-md text-white">
                <Link
                  to="/events"
                  className="text-white hover:border-b-2 "
                  style={{ textDecoration: "none", fontFamily: "Lato" }}
                >
                  EVENTS
                </Link>
              </li>

              <li className=" text-md text-white">
                <Link
                  to="/team"
                  className="text-white hover:border-b-2 "
                  style={{ textDecoration: "none", fontFamily: "Lato" }}
                >
                  OUR TEAM
                </Link>
              </li>
              <li className=" text-md text-white">
                <Link
                  to="/blog"
                  className="text-white hover:border-b-2 "
                  style={{ textDecoration: "none", fontFamily: "Lato" }}
                >
                  BLOG
                </Link>
              </li>
              <li className=" text-md text-white">
                <Link
                  to="/activities"
                  className="text-white hover:border-b-2 "
                  style={{ textDecoration: "none", fontFamily: "Lato" }}
                >
                  ACTIVITIES
                </Link>
              </li>
            </ul>
          </div>

          <div className="flex flex-col  lg:mt-2 mt-4">
            <h6 className="uppercase font-semibold mb-2 flex text-white text-lg justify-center md:justify-center">
              SOCIAL
            </h6>
            <div className="flex justify-center  align-items-center">
              <div className="ml-2 flex justify-center align-items-center">
                <a href="https://www.youtube.com/channel/UC8GdFKUpYRT9Ij-8EF7hemg/featured">
                  <img
                    src="https://img.icons8.com/small/32/FFFFFF/youtube-play.png"
                    alt="Alt"
                  />
                </a>
              </div>
              <div className="ml-2 flex justify-center align-items-center">
                <a href="https://www.instagram.com/innovator_club_ldce/">
                  <img
                    src="https://img.icons8.com/small/32/FFFFFF/instagram-new.png"
                    alt="Alt"
                  />
                </a>
              </div>
              <div className="ml-2 flex justify-center align-items-center">
                <a href="mailto:innovatorclubldce@gmail.com">
                  <img
                    src="https://img.icons8.com/small/32/FFFFFF/gmail.png"
                    alt="Alt"
                  />
                </a>
              </div>
            </div>
          </div>
        </div>
      </div>
      <div className="text-center mt-6 text-white" style={style}>
        <span>© 2022 Copyright:Innovator Club</span>
      </div>
    </footer>
  );
}

export default Footer;
