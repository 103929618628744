import React from "react";

import "./Card.css";

const Card = ({ name, designation, image, dept, instagram, linkdin }) => {
  return (
    <>
      <div className="main">
        <div className="card-wrapper">
          <div className="card">
            <div className="card-image">
              <img src={image} alt={name} />
            </div>

            <ul className="social-icons">
              <li>
                <a href={linkdin} target="_blank" rel="noreferrer">
                  <i className="fa-brands fa-linkedin-in"></i>
                </a>
              </li>
              <li>
                <a href={instagram} target="_blank" rel="noreferrer">
                  <i className="fab fa-instagram"></i>
                </a>
              </li>
            </ul>
            <div className="details">
              <h2>
                {name}
                <br />
                <span className="job-title">{dept}</span>
              </h2>
            </div>
          </div>
        </div>
        <div className="position">
          <h4 id="role">{designation}</h4>
        </div>
      </div>
    </>
  );
};

export default Card;
