import "./Team_Card.module.css";

const CardMember = ({ props }) => {
  return (
    <div className="card_Member_Page py-4 bg-white ">
      <div className="card-div_Member_Page">
        <img className="card-image_Member_Page" src={props.image} alt="" />
        <p className="card-title_Member_Page  ">{props.name}</p>
      </div>
    </div>
  );
};

export default CardMember;
