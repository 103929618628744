import { AnimatePresence, motion } from "framer-motion";
import { MdClose } from "react-icons/md";

import useDrawer from "../../utils/useDrawer";
import { NAV_ITEMS_ARRAY } from "../../utils/constants";
import { NavLink } from "react-router-dom";

const Drawer = () => {
  const { state: sideBar, setState: setSideBar } = useDrawer();

  return (
    <>
      <AnimatePresence>
        {sideBar && (
          <>
            <motion.div
              initial={{ y: "-100%" }}
              animate={{
                y: 0,
              }}
              exit={{
                y: "100%",
              }}
              transition={{ type: "spring", bounce: 0, duration: 0.4 }}
              className="fixed bg-[#222c5675] backdrop-blur-lg text-white shadow-lg top-0 right-0 z-10 w-full h-screen p-5 overflow-hidden flex flex-col justify-between"
            >
              {/* close button */}
              <div>
                <motion.button
                  initial={{ scale: 0 }}
                  animate={{ scale: 1 }}
                  transition={{ duration: 0.3, delay: 0.2 }}
                  viewport={{ once: false }}
                  whileTap={{ scale: 0.8 }}
                  onClick={() => setSideBar(!sideBar)}
                  className=" text-white/80 absolute bottom-6 right-6 m-2"
                >
                  <MdClose size={40} />
                </motion.button>
              </div>
              <div className="flex flex-col items-center justify-evenly my-20 h-full text-3xl opacity-60">
                {NAV_ITEMS_ARRAY.map((item, index) => (
                  <motion.li
                    whileTap={{ scale: 0.9 }}
                    key={item.path + item.title + "DRAWER_ITEMS_LINK" + index}
                    onClick={() => setSideBar(!sideBar)}
                  >
                    <NavLink to={item.path}>{item.title}</NavLink>
                  </motion.li>
                ))}
              </div>
            </motion.div>

            {/* backdrop */}
            <motion.div
              initial={{ opacity: 0 }}
              animate={{
                opacity: 1,
              }}
              exit={{
                opacity: 0,
              }}
              transition={{ type: "spring", bounce: 0, duration: 0.2 }}
              onClick={() => setSideBar(!sideBar)}
              className="bg-black/50 px-5 fixed h-full w-full flex items-center justify-center inset-0 z-[5] overflow-hidden backdrop-blur-md"
            />
          </>
        )}
      </AnimatePresence>
    </>
  );
};

export default Drawer;
