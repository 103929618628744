import React from 'react'
import goal from '../../assets/goal.png'
import './Home3.css'

const Home3 = () => {
  return (
    <div className=" p-4 gap-8 home3 flex flex-col justify-around bg-[#0D102C]">
      <div className="space-y-10 text-center text-white">
        <h1 className=" font-bold text-4xl">Mission & Goals</h1>
        <p className=" text-xl  xl:text-center text-left ml-8   w-auto">
          To create an environment of self learning and self growth for the
          student, and to kick their technical as well as non-technical skills.
        </p>
      </div>

      <div className="bg_gredient w-11/12 home3_container rounded-[50px] bg-slate-400 px-12 py-6 mx-auto flex items-center justify-center md:justify-between">
        <div className="left">
          <ul className="flex font-bold xl:text-xl text-left pl-4  text-black flex-col space-y-4 ">
            <li>Narture innovative thinking</li>
            <li>
              Enhance the technical skills as well as non technical skills of
              students .
            </li>
            <li>Improve their team working skills</li>
            <li>Provide resources for the application of their ideas</li>
            <li>Improve their communication skills</li>
          </ul>
        </div>
        <div className="right">
          <img className=" h-96" src={goal} alt="" />
        </div>
      </div>
    </div>
  )
}

export default Home3
