export const NAV_ITEMS_ARRAY = [
  {
    title: "Home",
    path: "/",
  },
  {
    title: "Events",
    path: "/events",
  },
  {
    title: "Our Team",
    path: "/team",
  },
  {
    title: "Blog",
    path: "/blog",
  },
  {
    title: "Activities",
    path: "/activities",
  },
];
