import React from "react";

import Home1 from "../../Components/Home1/Home1";
import Home2 from "../../Components/Home2/Home2";
import Home3 from "../../Components/Home3/Home3";
import Home4 from "../../Components/Home4/Home4";
import Header from "../../Components/Header/Header";
import Footer from "../../Components/Footer/Footer";

const HomePage = () => {
  return (
    <>
      <div className="allhome">
        <Header />
        <Home1 />
        <Home2 />
        <Home3 />
        <Home4 />

        {/* footer section */}
        <div className=" grid place-items-center  w-full  my-8 ">
          <div>
            <p className="text-2xl text-bold mr-4">Visitors Count</p>
          </div>
          <div>
            <img
              src="https://counter4.optistats.ovh/private/freecounterstat.php?c=27uu2tnjl9frenrpm4as7md1y8rfbruw"
              border="0"
              title="Innovator Club Visitors Counter"
              alt="Visitors Counter"
            />
          </div>
        </div>
        <Footer />
      </div>
    </>
  );
};

export default HomePage;
