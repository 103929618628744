import React, { useEffect } from "react";
import "./EventDetails.css";
import { Carousel } from "react-responsive-carousel";
import "react-responsive-carousel/lib/styles/carousel.min.css";
import Header from "../../Components/Header/Header";
import Footer from "../../Components/Footer/Footer";
import { useParams } from "react-router-dom";
import EventDetail from "../../EventDetails.json";
function EventDetails() {
  useEffect(() => {
    window.scrollTo(0, 0);
  }, []);
  const { eventname } = useParams();
  const header = {
    color: "#ffe99b",
  };
  const button = {
    backgroundColor: "#ee7223",
    color: "white",
  };

  return (
    <>
      <Header />
      <div>
        <script src="https://cdn.tailwindcss.com"></script>

        <section className="  sectionpart bg-[#0d102c]">
          <h1 className="text-4xl text-black-700 text-center mt-5">
            {EventDetail[eventname]["Event"]}
          </h1>
          <div className="flex justify-center text-center">
            <div className="text-center  w-full flex justify-center m-8">
              <img
                src={EventDetail[eventname] && EventDetail[eventname]["banner"]}
                alt="event detail"
                className="w-80 h-auto"
              />
            </div>
          </div>
          {/* overview */}

          <div className="rules border-red-50 text-white p-8 ">
            <div className="border-l-8 rounded h-full border-green-300 ">
              <h2 style={header} className="ml-4 -400 text-4xl font-bold ">
                Overview :
              </h2>
            </div>

            <div className="p1 px-6 text-justify">
              {EventDetail[eventname] && EventDetail[eventname]["text"]}
            </div>
          </div>

          {/* Event details */}
          {eventname === "INN-O-SPARK-22" && (
            <>
              {/* quiz structure */}
              <div className="rules border-red-50 text-white p-8 ">
                <div className="border-l-8 rounded h-full border-green-300 ">
                  <h2 style={header} className="ml-4 -400 text-4xl font-bold ">
                    Quiz Structure :
                  </h2>
                </div>

                <div className="grid  grid-rows-1 grid-flow-row lg:grid-cols-2 gap-4 mb-8">
                  <div className="border-2 rounded p-4 mt-8 box ">
                    <h4 style={header} className="text-2xl  text-center ">
                      The quiz competition will be held in three genres:
                    </h4>
                    <ul className="list-decimal list-inside">
                      <li className="text-xl break-normal flex justify-center mt-8 text-white">
                        1.) &nbsp; General
                      </li>
                      <li className="text-xl flex justify-center break-normal mt-8 text-white ">
                        2.) &nbsp; Technical
                      </li>
                      <li className="text-xl flex justify-center break-normal mt-8 text-white ">
                        3.) &nbsp; Aptitude
                      </li>
                    </ul>
                  </div>

                  <div className="border-2 rounded p-4 mt-8 box">
                    <h3 style={header} className="text-2xl -400 text-center ">
                      Details
                    </h3>
                    <ul className="list-decimal  list-inside">
                      <li className="text-xl break-normal flex justify-start mt-8 text-white">
                        It is going to be held in hybrid mode, both online and
                        offline.
                      </li>
                      <li className="text-xl break-normal flex justify-start  mt-8 text-white">
                        In online mode, quiz will be conducted in two rounds.
                      </li>
                      <li className="text-xl break-normal flex justify-start  mt-8 text-white">
                        In offline mode, three rounds will be conducted.
                      </li>
                    </ul>
                  </div>
                </div>
              </div>

              {/* Workshop */}

              <div className="rules border-red-50 text-white p-8 ">
                <div className="border-l-8 rounded h-full border-green-300 ">
                  <h2 style={header} className="ml-4 -400 text-4xl font-bold ">
                    Offline Workshop:
                  </h2>
                </div>

                <div className="grid grid-rows-1 grid-flow-row lg:grid-cols-2 gap-4 mb-8 mt-8">
                  <div
                    className="flex flex-col mx-auto align-items-center image p-4 transform transition duration-500 hover:scale-90 border-2 rounded-xl"
                    style={{
                      backgroundColor: "#808080",
                      backgroundImage: `url(https://www.mooc.org/hubfs/web-programming-languages.jpg)`,
                      height: "20rem",
                      width: "20rem",
                    }}
                  >
                    <div className=" ">
                      <p className="text-3xl text-white text-center">
                        Web Development
                      </p>
                    </div>
                  </div>

                  <div
                    className="flex flex-col mx-auto align-items-center image  p-4 transform transition duration-500 hover:scale-90 border-2 rounded-xl"
                    style={{
                      backgroundColor: "#808080",
                      backgroundImage:
                        "url(https://di3xp7dfi3cq.cloudfront.net/media/catalog/product/cache/87fcef89bd0998a2013a244009dca6c6/p/y/python_3.jpg)",
                      height: "20rem",
                      width: "20rem",
                    }}
                  >
                    <div className=" ">
                      <p className="text-3xl text-white text-center">
                        Data Visualisation with Python
                      </p>
                    </div>
                  </div>
                </div>
              </div>

              {/* Event Detials And structure */}

              <div className="rules border-red-50 text-white p-8 ">
                <div className="border-l-8 rounded h-full border-green-300 ">
                  <h2 style={header} className="ml-4  text-4xl font-bold ">
                    Event Schedule And Rules
                  </h2>
                </div>

                {EventDetail[eventname]["rules"].length > 0 && (
                  <div className="flex justify-center mt-8 text-center mb-8 flex-col">
                    <div className="flex justify-center text-center">
                      <div className="slider">
                        <Carousel
                          className="slider"
                          interval={4000}
                          swipeable={true}
                          autoPlay={true}
                        >
                          {EventDetail[eventname]["rules"].map((image) => {
                            return (
                              <div>
                                <img
                                  style={{
                                    backgroundSize: "fit",
                                    objectFit: "contain",
                                  }}
                                  src={image}
                                  width="400px"
                                  height="400px"
                                  alt="images"
                                />
                              </div>
                            );
                          })}
                        </Carousel>
                      </div>
                      <div className="border-2"></div>
                    </div>
                  </div>
                )}
              </div>

              {/* registration detials */}
              <div className="rules border-red-50 text-white p-8 ">
                <div className="border-l-8 rounded h-full border-green-300 ">
                  <h2 style={header} className="ml-4 -400 text-4xl font-bold ">
                    Registration Fees :
                  </h2>
                </div>

                <div className="grid  grid-rows-1 grid-flow-row lg:grid-cols-3 gap-4 mb-8">
                  <div className="border-2 rounded p-4 mt-8 box ">
                    <h3 style={header} className="text-2xl -400 text-center ">
                      Online Quiz
                    </h3>
                    <ul className="list-decimal list-inside">
                      <li className="text-xl break-normal flex justify-center mt-8 text-white">
                        Rs.20 (Per Quiz)
                      </li>
                      <li className="text-xl flex justify-center break-normal mt-8 text-white ">
                        Rs.50 (All Quiz)
                      </li>
                    </ul>
                  </div>

                  <div className="border-2 rounded p-4 mt-8 box">
                    <h3 style={header} className="text-2xl -400 text-center ">
                      Offline Quiz
                    </h3>
                    <ul className="list-decimal  list-inside">
                      <li className="text-xl break-normal flex justify-center mt-8 text-white">
                        Rs.50 (Per Quiz)
                      </li>
                      <li className="text-xl break-normal flex justify-center  mt-8 text-white">
                        Rs.120 (All Quiz)
                      </li>
                    </ul>
                  </div>

                  <div className="border-2 rounded p-4 mt-8 box">
                    <h3 style={header} className="text-2xl -400 text-center ">
                      Workshop
                    </h3>
                    <ul>
                      <li className="text-xl flex align-items-center justify-center break-normal mt-8 text-white ">
                        Rs.80 (Per Workshop)
                      </li>
                    </ul>
                  </div>
                </div>
                <div className="flex justify-center align-items-center mb-5">
                  <div className="border-2 rounded p-4 mt-8 box">
                    <h3 style={header} className="text-2xl -400 text-center ">
                      Combo Offer
                    </h3>
                    <ul className="list-decimal f  list-inside">
                      <li className="text-xl break-normal text-center mt-8 text-white">
                        Rs.150 (2 Quiz + 1 Workshop)
                      </li>
                      <li className="text-xl break-normal text-center  mt-8 text-white">
                        Rs.250 (All Quiz + All Workshop)
                      </li>
                    </ul>
                  </div>
                </div>
              </div>

              <div className="flex justify-center align-items-center">
                <a
                  href="https://forms.gle/bUqqN45CAUTUAHXz5"
                  target="_blank"
                  rel="noreferrer"
                >
                  <button
                    className="event-btn  rounded-md mb-8 p-2 "
                    style={button}
                  >
                    REGISTER NOW
                  </button>
                </a>
              </div>
            </>
          )}

          {/* Glimpse */}
          {EventDetail[eventname]["images"].length > 0 && (
            <div className="flex justify-center text-center mb-8 flex-col">
              <div className="g1">Glimpses</div>
              <div className="flex justify-center text-center">
                <div className="slider">
                  <Carousel
                    className="slider"
                    interval={4000}
                    swipeable={true}
                    autoPlay={true}
                  >
                    {EventDetail[eventname]["images"].map((image) => {
                      return (
                        <div>
                          <img src={image} alt="images" />
                        </div>
                      );
                    })}
                  </Carousel>
                </div>
                <div className="border-2"></div>
              </div>
            </div>
          )}

          {/* sponsors */}
          {EventDetail[eventname]["sponsors"] && (
            <div className="flex justify-center text-center mb-8 flex-col">
              <div className="g1">Sponsors</div>
              <div className="flex justify-center text-center">
                <div className="sponsors">
                  <Carousel
                    className="sponsors"
                    interval={4000}
                    swipeable={true}
                    autoPlay={true}
                  >
                    {EventDetail[eventname]["sponsors"].map((image) => {
                      return (
                        <div>
                          <img src={image} width="300px" alt="images" />
                        </div>
                      );
                    })}
                  </Carousel>
                </div>
              </div>
            </div>
          )}
          <div className=" grid place-items-center  w-full  my-8 ">
            <div>
              <p className="text-2xl text-bold mr-4">Vistors Count</p>
            </div>
            <div>
              <div>
                <img
                  src="https://counter4.optistats.ovh/private/freecounterstat.php?c=27uu2tnjl9frenrpm4as7md1y8rfbruw"
                  border="0"
                  title="Innovator Club Vistor Counter"
                  alt="Vister Counter"
                />
              </div>
            </div>
          </div>
          <Footer />
        </section>
      </div>
    </>
  );
}

export default EventDetails;
